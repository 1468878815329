/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { createAction, props } from '@ngrx/store';
import { Contact } from '../model/contact';
import { SpeedDial, SpeedDialLayout } from '../model/speed-dial';
import { Folder } from '../model/folder';
import { UIDialSource, UserAuthenticationRecord } from 'CalltakingCoreApi';
import { AdrSpeedDial } from '../model/adr-speed-dial';

export const directoryEffectsInitialized = createAction('[Directory] Effects Initialized');

export const fetchDirectory = createAction('[Directory] Fetch Directory');
export const fetchContacts = createAction('[Directory] Fetch Contacts');
export const fetchContactsSuccess = createAction('[Directory] Fetch Contacts Success', props<{ contacts: Contact[] }>());
export const fetchContactsFail = createAction('[Directory] Fetch Contacts Fail', props<{ payload: string }>());
export const fetchDirectoryEntries = createAction('[Directory] Fetch Entries');
export const fetchDirectoryEntriesSuccess = createAction('[Directory] Fetch Entries Success', props<{ entries: (Folder | SpeedDial)[] }>());
export const fetchDirectoryEntriesFail = createAction('[Directory] Fetch Entries Fail', props<{ payload: string }>());
export const fetchSpeedDialLayouts = createAction('[Directory] Fetch Speed Dial Layouts');
export const fetchSpeedDialLayoutsSuccess = createAction('[Directory] Fetch Speed Dial Layouts Success', props<{ layouts: SpeedDialLayout[] }>());
export const fetchSpeedDialLayoutsFailure = createAction('[Directory] Fetch Speed Dial Layouts Failure', props<{ payload: string }>());
export const dialContact = createAction('[Directory] Dial Contact', props<{ contact: Contact }>());
export const dialSpeedDial = createAction('[Directory] Dial Speed Dial', props<{ speedDial: SpeedDial }>());
export const conferenceAdrSpeedDial = createAction('[Directory] Dial ADR Speed Dial', props<{ speedDial: AdrSpeedDial }>());
export const dialAgent = createAction('[Directory] Dial Agent', props<{ agent: UserAuthenticationRecord }>());
export const dialNumber = createAction('[Directory] Dial Number', props<{ number: string; label?: string }>());
export const dialOrConference = createAction(
    '[Directory] Dial or Conference',
    props<{ number: string; source: UIDialSource; label?: string; contactId?: string, blindTransfer?: boolean }>()
);

export const selectFolder = createAction('[Directory] Speed Dial Folder Select', props<{ folder: Folder, suggested: boolean }>());
export const initiateSMSCallForContact = createAction('[Directory] Initiate SMS Call For Contact', props<{ contact: Contact }>());
export const initiateSMSCallForContactFail = createAction('[Directory] Initiate SMS Call For Contact Fail', props<{ payload: string }>());
export const requestInitiateSMSCallForNumber = createAction('[Directory] Request Initiate SMS Call For Number', props<{ number: string }>());
export const initiateSMSCallForNumber = createAction('[Directory] Initiate SMS Call For Number', props<{ number: string }>());
export const initiateSMSCallForNumberSuccess = createAction('[Directory] Initiate SMS Call For Number Success', props<{ number: string }>());
export const initiateSMSCallForNumberFail = createAction('[Directory] Initiate SMS Call For Number Fail', props<{ payload: string }>());

export const dialPadInput = createAction('[Directory] DialPad Input', props<{ input: string }>());
export const dialPadBackspace = createAction('[Directory] DialPad Backspace');
export const dialPadClear = createAction('[Directory] DialPad Clear');
export const dialPadDial = createAction('[Directory] DialPad Dial');

export const hookFlash = createAction('[Directory] Hook Flash');
export const hookFlashSuccess = createAction('[Directory] Hook Flash Success');
export const hookFlashFailure = createAction('[Directory] Hook Flash Failure', props<{ payload: string }>());

export const dialPadButtonPress = createAction('[Directory] DialPad Button Press', props<{ digit: string }>());
export const resetSuggestedFolderPath = createAction('[Directory] Reset suggested folder path');
